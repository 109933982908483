export default defineNuxtPlugin((nuxtApp) => {
  // Create a reactive state to store the language.
  // On the server, you can extract the language from req.headers or cookies.
  const detectedLanguage = useState('locale', () => {
    if (process.server) {
      // TODO share hosts here if needed
      // Your detection logic – e.g. using nuxtApp.ssrContext.req
      const i18n_interface = nuxtApp.ssrContext?.event?.i18n_interface;
      return i18n_interface.locale ?? 'en';
    }

    return 'en';
  });
  console.info('smart-t detectedLanguage', detectedLanguage);
  
  // Preserve the original $t function
  const originalT = nuxtApp.$i18n.t.bind(nuxtApp.$i18n);
  
  const customT = (key, values = {}, options = {}) => {
    options.locale = detectedLanguage.value;
    return originalT(key, values, options);
  };
  
  // Override the $t function globally
  nuxtApp.provide('t', customT);
  nuxtApp.$i18n.t = customT;
  nuxtApp.$i18n.locale.value = detectedLanguage.value;
});
